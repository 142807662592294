<template>
  <v-dialog width="650" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        elevation="0"
        height="30px"
        :disabled="active"
        outlined
        class="mt-3"
        v-on="on"
      >
        {{ $t("configuration") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ channel.channel_name }}</v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-col>
            <v-card class="pa-4">
              <h4
                class="primary--text font-weight-regular text-uppercase text-decoration-none text-center"
              >
                {{ $t("sended_messages") }}
              </h4>
              <h1>{{ messagesSend }}</h1>
            </v-card>
          </v-col>

          <v-col>
            <PurchaseChannelPackage :channel="channel" @update="fetchTotal" />
          </v-col>
          <v-col>
            <v-card class="pa-4">
              <h4
                class="primary--text font-weight-regular text-uppercase text-decoration-none text-center"
              >
                {{ $t("remaining_messages") }}
              </h4>
              <h1>{{ messagesBuyed - messagesSend }}</h1>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table :items="items" :headers="headers">
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="inform(item)"><v-icon>$eye</v-icon></v-btn>
        </template>
      </v-data-table>
      <v-dialog v-model="internal_dialog" width="500">
        <v-card>
          <v-card-title>Informe {{ channel.channel_name }}</v-card-title>
          <v-simple-table v-if="selectedMessage">
            <tbody>
              <tr>
                <td>Cliente</td>
                <td>{{ selectedMessage.notifiable.fullname }}</td>
              </tr>
              <tr>
                <td>Telefono</td>
                <td>{{ selectedMessage.to }}</td>
              </tr>
              <tr>
                <td>Mensaje</td>
                <td>{{ selectedMessage.text_send }}</td>
              </tr>
              <tr>
                <td>Segmentos de mensaje</td>
                <td>{{ selectedMessage.segments }}</td>
              </tr>
              <tr>
                <td>Enviado</td>
                <td v-if="selectedMessage.created_at">
                  {{
                    $d(
                      new Date(selectedMessage.created_at.substring(0, 10)),
                      "dateShort"
                    )
                  }}
                  {{ selectedMessage.created_at.substring(11, 16) }}
                </td>
                <td v-else>-</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ChannelConfiguration",
  props: ["active", "channel"],
  components: {
    PurchaseChannelPackage: () => import("./PurchaseChannelPackage"),
  },
  data() {
    return {
      dialog: false,
      internal_dialog: false,
      headers: [
        {
          text: this.$tc("customer"),
          align: "center",
          sortable: false,
          value: "notifiable.fullname",
        },
        {
          text: this.$t("phone"),
          align: "center",
          sortable: false,
          value: "to",
        },
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      items: [],
      total: 0,
      messagesSend: 0,
      messagesBuyed: 0,
      selectedMessage: { notifiable: {} },
    };
  },
  mounted() {
    this.fetchExternalNotifications();
    this.fetchTotal();
  },
  methods: {
    ...mapActions("notifications", [
      "getExternalNotifications",
      "countExternalNotifications",
    ]),
    fetchExternalNotifications() {
      this.getExternalNotifications({
        filters: { channel_id: this.channel.id },
      }).then((response) => {
        this.total = response.total;
        this.items = response.data;
      });
    },
    fetchTotal() {
      this.countExternalNotifications({
        filters: { channel_id: this.channel.id },
      }).then((response) => {
        this.messagesSend = response.total;
        this.messagesBuyed = response.purchases;
      });
    },
    inform(message) {
      this.selectedMessage = message;
      this.internal_dialog = true;
    },
  },
};
</script>
